import { css, StyleSheet } from 'aphrodite'
import cn from 'classnames'
import { NavLink, useRouteMatch } from 'react-router-dom'
import React, { useState } from 'react'
import { getEnv } from '../utils/environment'
import AccountPanel from './AccountPanel'

export default function Layout({ children, padding = true, className }) {
    const { path } = useRouteMatch()
    const [menu, setMenu] = useState(false)

    return (
        <div>
            <div
                className={css(
                    styles.sidebar,
                    menu ? styles.desktopSidebar : styles.mobileSidebar,
                    getEnv() === 'development' ? styles.developmentSidebar : null,
                    getEnv() === 'staging' ? styles.stagingSidebar : null,
                )}
            >
                <div style={{ marginTop: 50, marginBottom: 0 }}>
                    <AccountPanel />
                </div>

                <i onClick={() => setMenu(false)} className={cn('icon ion-md-arrow-back is-size-4', css(styles.hiderIcon))} />

                <p className={css(styles.menuHeading)}>Меню</p>

                <ul className={css(styles.menu)}>
                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/cash-register" className={cn(css(styles.link), { [css(styles.active)]: path.startsWith('/cash-register') })}>
                            <i className={cn('icon ion-md-cash', css(styles.icon))} />
                            Касса
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/archive/orders" className={css(styles.link, path.startsWith('/archive/orders') ? styles.active : null)}>
                            <i className={cn('icon ion-md-archive', css(styles.icon))} />
                            Чеки
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/archive/order/null" className={css(styles.link, path.startsWith('/archive/order/') ? styles.active : null)}>
                            <i className={cn('icon ion-md-undo', css(styles.icon))} />
                            Возврат
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink className={css(styles.link, path.startsWith('/borrow') ? styles.active : null)} to="/borrow">
                            <i className={cn('icon ion-md-wallet', css(styles.icon))} />
                            Должники
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/documents-in" className={css(styles.link, path.startsWith('/documents-in') ? styles.active : null)}>
                            <i className={cn('icon ion-md-log-in', css(styles.icon))} />
                            Прием товаров &nbsp;
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/products" className={css(styles.link, path.startsWith('/products') ? styles.active : null)}>
                            <i className={cn('icon ion-md-folder', css(styles.icon))} />
                            Продукты &nbsp;
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/nomenclature" className={css(styles.link, path.startsWith('/nomenclature') ? styles.active : null)}>
                            <i className={cn('icon ion-md-list', css(styles.icon))} />
                            Номенклатура
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/supplier" className={css(styles.link, path.startsWith('/supplier') ? styles.active : null)}>
                            <i className={cn('icon ion-md-people', css(styles.icon))} />
                            Поставщики
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/client" className={css(styles.link, path.startsWith('/client') ? styles.active : null)}>
                            <i className={cn('icon ion-md-contacts', css(styles.icon))} />
                            Клиенты
                        </NavLink>
                    </li>

                    <li onClick={() => setMenu(false)}>
                        <NavLink to="/settings/user" className={cn(css(styles.link), { [css(styles.active)]: path.startsWith('/settings') })}>
                            <i className={cn('icon ion-md-settings', css(styles.icon))} />
                            Настройки
                        </NavLink>
                    </li>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/expense-report" className={css(styles.link, path.startsWith('/expense-report') ? styles.active : null)}>
                            <i className={cn('icon ion-md-document', css(styles.icon))} />
                            Отчет о затратах
                        </NavLink>
                    </li>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/inventory" className={css(styles.link, path.startsWith('/inventory') ? styles.active : null)}>
                            <i className={cn('icon ion-md-clipboard', css(styles.icon))} />
                            Инвентаризация
                        </NavLink>
                    </li>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/promotion" className={css(styles.link, path.startsWith('/promotion') ? styles.active : null)}>
                            <i className={cn('icon ion-md-pricetags', css(styles.icon))} />
                            Акции
                        </NavLink>
                    </li>
                    <li style={{ width: 'max-content' }} onClick={() => setMenu(false)}>
                        <NavLink to="/price-tags" className={css(styles.link, path.startsWith('/price-tags') ? styles.active : null)}>
                            <i className={cn('icon ion-md-pricetag', css(styles.icon))} />
                            Ценники
                        </NavLink>
                    </li>
                </ul>
            </div>

            <div className={cn(css(styles.container))}>
                <div className={cn(className, css(styles.content), { [css(styles.padding)]: padding })}>
                    <div>
                        <i onClick={() => setMenu(true)} className={cn('icon ion-md-menu is-size-3', css(styles.showerIcon))} />
                    </div>
                    {children}
                </div>
            </div>

            <div className={css(menu ? styles.background : null)} onClick={() => setMenu(false)} />
        </div>
    )
}

const styles = StyleSheet.create({
    sidebar: {
        minHeight: '100%',
        position: 'fixed',
        background: '#1f2668',
        width: '14.1rem',
        margin: '0',
        color: 'white',
        padding: '0 1.2rem 0 2.8rem',
        zIndex: '5',
        '::-webkit-scrollbar': { display: 'none' },
        '@media (max-height: 525px)': { height: 'calc(100vh - 100%)', overflowX: 'scroll' },
    },
    developmentSidebar: { background: 'RGBA(31, 38, 104)' },
    stagingSidebar: { background: 'RGBA(31, 38, 104)' },
    desktopSidebar: { '@media (max-width: 769px)': { transform: 'translateX(0)', transition: 'all 300ms' } },
    mobileSidebar: { '@media (max-width: 769px)': { transform: 'translateX(-14rem)', transition: 'all 300ms' } },
    icon: { margin: '0 0.3rem 0.9rem 0' },
    link: { color: 'RGBA(255, 255, 255, .6)' },
    logo: { margin: '3.4rem 0.1rem', width: '8rem' },
    menuHeading: { color: 'RGBA(255,255,255, .6)', margin: '0 0 1rem 0', fontSize: 22, fontWeight: 700 },
    menu: { margin: '0 0 3.1rem 0' },
    padding: { padding: '1.8rem' },
    container: { margin: '0 0 0 14rem', '@media (max-width: 769px)': { margin: '0' } },
    active: { fontWeight: 'bolder', color: 'white' },
    content: { minHeight: '100vh' },
    showerIcon: { '@media (min-width: 769px)': { display: 'none' } },
    hiderIcon: { transform: 'translate(9.6rem, -15rem)', color: 'white', '@media (min-width: 769px)': { display: 'none' } },
    background: { '@media (max-width: 769px)': { bottom: 0, left: 0, position: 'fixed', right: 0, top: 0, background: 'RGBA(0, 0, 0, .5)', zIndex: 4 } },
})
