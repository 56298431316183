/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import cn from 'classnames'
import ClientSelect from './ClientSelect'
import Input from '../../components/common/Input'
import Select from '../../components/common/Select'
import { currency, getCurrency } from '../../utils/types'
import Button from '../../components/common/Button'
import Input2 from '../../components/common/Input2'
import { useRightModal } from '../../hooks/useRightModal'
import Confirmation from './Confirmation'
import { usePostRequest } from '../../hooks/request'
import { BORROW_LIST } from '../../urls'
import { useMessage } from '../../hooks/message'

export default function PaymentModal({ setFieldValue, totals, cash, values, loading, paidAmount, handleSubmit, setIsPaymentOpen, setClientId, orderId }) {
    const lend = totals.totalSaleSum - paidAmount
    const shortChange = paidAmount - totals.totalSaleSum

    const [showConfirm, hideConfirm] = useRightModal(
        <Confirmation
            cash={cash}
            onCancel={() => {
                hideConfirm()
                setIsPaymentOpen(false)
            }}
            onConfirm={() => {
                hideConfirm()
                handleSubmit(values)
            }}
            values={values}
            totals={totals}
            loading={loading}
        />,
    )
    const borrowCreate = usePostRequest({ url: BORROW_LIST })
    const [showMessage] = useMessage()

    async function onSubmit(data) {
        const { success } = await borrowCreate.request({ data: { ...data, client: 1, amount: lend, order: orderId, currency: values.currency, description: values?.lendComment } })

        if (success) {
            setIsPaymentOpen(false)
            handleSubmit(values)
            showMessage('Успешно сохранен', 'is-success')
        }
    }

    return (
        <>
            <p style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }} className="panel-tabs">
                <a
                    onClick={() => {
                        setFieldValue('isLend', false)
                        setFieldValue('cash', totals.totalSaleSum)
                    }}
                    className={cn({ 'is-active': !values.isLend })}
                >
                    <b>Оплата</b>
                </a>
                <a
                    onClick={() => {
                        setFieldValue('isLend', true)
                        setFieldValue('cash', '')
                        setFieldValue('bankCard', '')
                    }}
                    className={cn({ 'is-active': values.isLend })}
                >
                    <b>В долг</b>
                </a>
            </p>

            <main className="mt-4">
                <ClientSelect setClientId={setClientId} values={values} setFieldValue={setFieldValue} />

                <Select name="currency" label="Валюта" options={currency} optionValue="value" disabled={loading} />

                {!values.isLend && (
                    <article>
                        <Input type="number" name="cash" label="Наличные" disabled={!!values.bankCard} min="0" step={values.currency === 'usd' ? '0.01' : '1'} />
                        <Input type="number" name="bankCard" label="Банковская карта" disabled={!!values.cash} min="0" step={values.currency === 'usd' ? '0.01' : '1'} />
                        <div className="tag is-large is-link is-light is-block mt-4">
                            К ОПЛАТЕ: <b className="mx-1">{totals.totalSaleSum.toFixed(2)}</b> {getCurrency(values.currency)}
                        </div>
                        {paidAmount > totals.totalSaleSum && (
                            <div className="tag is-large is-link is-light is-block mt-4">
                                СДАЧА: <b className="mx-1">{shortChange ? shortChange.toFixed(2) : 0}</b> {getCurrency(values.currency)}
                            </div>
                        )}
                        <Button onClick={showConfirm} disabled={loading || paidAmount < totals.totalSaleSum} type="submit" text="ПРИНЯТЬ" className="is-block mt-4 is-info is-fullwidth" />
                    </article>
                )}

                {values.isLend && (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <aside>
                            <Input name="lendReturnDate" type="date" label="Дата возврата" />
                            <Input name="lendComment" component="textarea" label="Примечание" />
                        </aside>

                        <article>
                            <Input2
                                type="number"
                                name="cash"
                                label="Наличные"
                                disabled={!!values.bankCard}
                                min="0"
                                max={totals.totalSaleSum}
                                step={values.currency === 'usd' ? '0.01' : '1'}
                                onChange={(e) => {
                                    if (+e.target.value < totals.totalSaleSum) setFieldValue('cash', e.target.value)
                                }}
                            />
                            <Input2
                                type="number"
                                name="bankCard"
                                label="Банковская карта"
                                disabled={!!values.cash}
                                min="0"
                                max={totals.totalSaleSum}
                                step={values.currency === 'usd' ? '0.01' : '1'}
                                onChange={(e) => {
                                    if (+e.target.value < totals.totalSaleSum) setFieldValue('cash', e.target.value)
                                }}
                            />
                            <div className="tag is-large is-link is-light is-block mt-4">
                                К ОПЛАТЕ: <b className="mx-1">{totals.totalSaleSum.toFixed(2)}</b> {getCurrency(values.currency)}
                            </div>

                            <div className="tag is-large is-link is-light is-block mt-4">
                                СУММА ДОЛГА: <b className="mx-1">{lend.toFixed(2)}</b> {getCurrency(values.currency)}
                            </div>

                            <Button onClick={() => onSubmit()} disabled={loading} type="submit" text="ПРИНЯТЬ" className="is-block mt-4 is-info is-fullwidth" />
                        </article>
                    </div>
                )}
            </main>
        </>
    )
}
