import React, { useEffect, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import cn from 'classnames'
import { css, StyleSheet } from 'aphrodite'
import { useLoad, usePutRequest } from '../../hooks/request'
import { currency, getCurrency, getMeasurement, tradeTypes } from '../../utils/types'
import Button from '../../components/common/Button'
import ReactModal from '../../components/common/ReactModal'
import { PRODUCT_LIST } from '../../urls'
import Table from '../../components/common/Table'
import Card from '../../components/common/Card'
import { format } from '../../utils/number'
import Input from '../../components/common/Input'
import SearchFilter2 from '../../components/common/SearchFilter2'
import ClientSelect from './ClientSelect'
import PaymentModal from './PaymentModal'
import { useMessage } from '../../hooks/message'
import CurrencySelect from '../../components/common/CurrencySelect'
import TradeTypeSelect from '../../components/common/TradeTypeSelect'

export default function CashRegisterForm({ formData, updateFormData, cash }) {
    const [isPaymentOpen, setIsPaymentOpen] = useState(false)
    const [params, setParams] = useState({})
    const productsLoad = useLoad({ url: PRODUCT_LIST, params: { ...params, size: 12 } }, [params])
    const productsList = productsLoad.response?.results || []
    const products = productsList.map((item) => ({ ...item, count: item?.count || '', priceSale: item?.priceSale || '', quantity: '1' }))
    const { request, loading } = usePutRequest()
    const [clientId, setClientId] = useState()
    console.log(clientId)
    const [showMessage] = useMessage()

    const handleSubmit = async (vals) => {
        const { success } = await request({
            url: `main/order_simple_update/${vals.id}`,
            data: { items: vals.products },
        })
        if (success) {
            setIsPaymentOpen(false)
            cash.request()
            showMessage('Успешно сохранен', 'is-success')
        }
    }

    return (
        <Formik enableReinitialize initialValues={{ tradeType: tradeTypes[0].value, currency: currency[0].value, client: null, products: [], cash: '', bankCard: '', isLend: false, ...formData }}>
            {({ setFieldValue, values }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                    updateFormData(values)
                }, [values])

                const totals = values.products.reduce(
                    (acc, product) => {
                        const quantity = product.quantity ? +product.quantity : 0
                        // Calculate price based on currency and tradeType
                        const getPrice = () => {
                            if (values.currency === 'sum') {
                                if (values.tradeType === 'retail') {
                                    return product?.sumPriceSale || 0
                                }
                                if (values.tradeType === 'wholesale') {
                                    return product?.sumPriceTransfer || 0
                                }
                            } else if (values.currency === 'usd') {
                                if (values.tradeType === 'retail') {
                                    return product?.usdPriceSale || 0
                                }
                                if (values.tradeType === 'wholesale') {
                                    return product?.usdPriceTransfer || 0
                                }
                            }
                            return 0 // default value if conditions are not met
                        }

                        const price = getPrice() // Use price obtained from getPrice
                        // const priceSale = product.priceSale ? +product.priceSale : 0;

                        // const priceSale = product.priceSale ? +product.priceSale : 0
                        acc.totalQuantity += quantity
                        acc.totalSaleSum += quantity * price
                        return acc
                    },
                    { totalQuantity: 0, totalSaleSum: 0 },
                )

                const paidAmount = +values.cash || +values.bankCard
                console.log(formData)
                return (
                    <Form>
                        <header style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 200px))' }}>
                            <TradeTypeSelect name="tradeType" label="Тип торговли" options={tradeTypes} optionValue="value" disabled={loading} />

                            <CurrencySelect name="currency" label="Валюта" options={currency} optionValue="value" disabled={loading} />

                            <ClientSelect formData={formData} values={values} setFieldValue={setFieldValue} setClientId={setClientId} />
                        </header>

                        <Card>
                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => (
                                    <>
                                        <Table
                                            emptyMessage="Добавьте товар"
                                            columns={{
                                                name: 'Наименование товара',
                                                remains: 'Остаток',
                                                quantity: 'Количество',
                                                measurement: 'Ед. изм.',
                                                priceSale: 'Цена за единицу',
                                                summ: 'К оплате',
                                                actions: '',
                                            }}
                                            items={values.products}
                                            renderItem={(item, index) => {
                                                const quantity = +values.products?.[index].quantity || 0

                                                const getPrice = () => {
                                                    if (values.currency === 'sum') {
                                                        if (values.tradeType === 'retail') {
                                                            return item?.sumPriceSale || 0
                                                        }
                                                        if (values.tradeType === 'wholesale') {
                                                            return item?.sumPriceTransfer || 0
                                                        }
                                                    } else if (values.currency === 'usd') {
                                                        if (values.tradeType === 'retail') {
                                                            return item?.usdPriceSale || 0
                                                        }
                                                        if (values.tradeType === 'wholesale') {
                                                            return item?.usdPriceTransfer || 0
                                                        }
                                                    }
                                                    return 0 // default value if conditions are not met
                                                }

                                                const price = getPrice()
                                                // const price = +values.products[index].priceSale

                                                const sum = format(quantity * price)

                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.count}</td>
                                                        <td>
                                                            <Input
                                                                validate={(val) => {
                                                                    let error
                                                                    if (!val) {
                                                                        error = 'Это поле обязательно'
                                                                    }
                                                                    if (val === '0') {
                                                                        error = 'Это поле обязательно'
                                                                    }
                                                                    return error
                                                                }}
                                                                type="number"
                                                                className="is-size-7"
                                                                name={`products[${index}].quantity`}
                                                                disabled={loading}
                                                            />
                                                        </td>
                                                        <td>{getMeasurement(item.measurement)}</td>

                                                        <td>
                                                            {' '}
                                                            {values.currency === 'sum' && values.tradeType === 'retail' && format(item?.sumPriceSale)}
                                                            {values.currency === 'sum' && values.tradeType === 'wholesale' && format(item?.sumPriceTransfer)}
                                                            {values.currency === 'usd' && values.tradeType === 'retail' && format(item?.usdPriceSale)}
                                                            {values.currency === 'usd' && values.tradeType === 'wholesale' && format(item?.usdPriceTransfer)} {getCurrency(values.currency)}
                                                        </td>

                                                        {/* <td>{item.priceSale}</td> */}
                                                        <td>{format(sum) ?? ''}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                                <Button
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    icon="close-outline"
                                                                    className="is-danger is-outlined is-size-7"
                                                                    disabled={loading}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }}
                                        />

                                        <hr />

                                        <main style={{ display: 'flex', gap: '1rem' }} className="mt-6">
                                            <div style={{ flex: 'auto' }}>
                                                <SearchFilter2 setSearchParams={setParams} />

                                                <Table
                                                    columns={{ code: 'Штрих-код', name: 'Наименование товара', quantity: 'Остаток', priceSale: 'Цена' }}
                                                    items={products.filter((prod) => !values.products.map((p) => p?.id).includes(prod?.id))}
                                                    renderItem={(item) => (
                                                        <tr
                                                            className={cn(css(styles.product))}
                                                            key={item?.id}
                                                            onClick={() => {
                                                                if (!loading) {
                                                                    arrayHelpers.push(item)
                                                                }
                                                            }}
                                                        >
                                                            <td>{item?.barcode}</td>
                                                            <td>{item?.name}</td>
                                                            <td>
                                                                {item?.count || 0} {getMeasurement(item?.measurement)}
                                                            </td>
                                                            <td>
                                                                {values.currency === 'sum' && values.tradeType === 'retail' && format(item?.sumPriceSale)}
                                                                {values.currency === 'sum' && values.tradeType === 'wholesale' && format(item?.sumPriceTransfer)}
                                                                {values.currency === 'usd' && values.tradeType === 'retail' && format(item?.usdPriceSale)}
                                                                {values.currency === 'usd' && values.tradeType === 'wholesale' && format(item?.usdPriceTransfer)} {getCurrency(values.currency)}
                                                            </td>
                                                        </tr>
                                                    )}
                                                />
                                            </div>

                                            <aside style={{ minWidth: 300 }}>
                                                <div className="tag is-large is-link is-light">
                                                    К ОПЛАТЕ: <b className="mx-1">{totals.totalSaleSum.toFixed(2)}</b> {getCurrency(values.currency)}
                                                </div>

                                                <div style={{ display: 'grid', gap: '1rem' }} className="mt-4">
                                                    <Button
                                                        onClick={() => {
                                                            setIsPaymentOpen(true)
                                                            setFieldValue('cash', totals.totalSaleSum)
                                                        }}
                                                        text="Оплата"
                                                        icon="shield-checkmark-outline"
                                                        className="is-success"
                                                        disabled={loading || values.products.length === 0}
                                                    />

                                                    <ReactModal style={{ content: { minWidth: 550 } }} isOpen={isPaymentOpen} setIsOpen={setIsPaymentOpen}>
                                                        <PaymentModal
                                                            orderId={formData?.id}
                                                            setClientId={setClientId}
                                                            cash={cash}
                                                            handleSubmit={handleSubmit}
                                                            loading={loading}
                                                            paidAmount={paidAmount}
                                                            setFieldValue={setFieldValue}
                                                            totals={totals}
                                                            values={values}
                                                            setIsPaymentOpen={setIsPaymentOpen}
                                                        />
                                                    </ReactModal>

                                                    <Button
                                                        onClick={() => {
                                                            updateFormData({})
                                                        }}
                                                        icon="close-outline"
                                                        text="Отмена"
                                                        className="is-outlined is-danger"
                                                        disabled={loading}
                                                    />
                                                </div>
                                            </aside>
                                        </main>
                                    </>
                                )}
                            />
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}

const styles = StyleSheet.create({ product: { ':hover': { backgroundColor: 'aliceblue', cursor: 'pointer' } } })
