/* eslint-disable max-len */
import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '../hooks/queryString'
import { useLoad } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { SUPPLIER_DETAIL, SUPPLIER_ITEM_LIST } from '../urls'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import SupplierItemCreate from '../components/SupplierItemCreate'
import SupplierItemItem from '../components/SupplierItemItem'
import SupplierTabs from '../components/SupplierTabs'
import { format } from '../utils/number'
import NewTable from '../components/common/NewTable'
import SummaryCard from '../components/common/SummaryCard'

export default function SupplierChecksDetail() {
    const { supplierId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: SUPPLIER_DETAIL.replace('{id}', supplierId) })
    const className = classDetail.response
    const supplierItems = useLoad({
        url: SUPPLIER_ITEM_LIST,
        params: { supplier: supplierId, ...params },
    })

    const [showCreateModal, hideCreateModal] = useModal(
        <SupplierItemCreate
            groupName={className ? className.name : ''}
            supplierId={supplierId}
            onSuccess={() => {
                supplierItems.request()
                hideCreateModal()
            }} onCancel={() => hideCreateModal()} />,
    )

    return (
        <Layout>
            <SupplierTabs supplierId={supplierId} name={className ? className.name : ''} />

            <hgroup style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(18rem, 1fr))', gap: '1rem', marginBottom: '2rem' }}>
                <SummaryCard
                    label="Приход USD:"
                    value={`${format(className?.usdComingTotal || 0)} $`}
                />
                <SummaryCard
                    label="Приход USD:"
                    value={`${format(className?.sumComingTotal || 0)} сум`}
                />
                <SummaryCard
                    label="Приход USD:"
                    value={`${format(className?.usdConsumptionTotal || 0)} $`}
                />
                <SummaryCard
                    label="Приход USD:"
                    value={`${format(className?.sumConsumptionTotal || 0)} сум`}
                />
            </hgroup>

            <Button
                onClick={showCreateModal}
                text="Добавить"
                icon="add-outline"
                className="is-link is-outlined is-pulled-right" />
            <br />
            <NewTable
                loading={supplierItems.loading}
                items={supplierItems.response ? supplierItems.response.results : []}
                columns={{
                    id: '№',
                    name: 'Способ оплаты\t',
                    phone: 'Примечание',
                    phone_director: 'Дата\t',
                    // phone_accountant: 'Дата возврата\t',
                    currency: 'Валюта',
                    come: 'Приход',
                    buttons: 'Расход',
                    actions: '',
                }}
                totalCount={supplierItems.response ? supplierItems.response.count : 0}
                pageSize={15}
                renderItem={(item, index) => (
                    <SupplierItemItem
                        groupName={className ? className.name : ''}
                        index={index}
                        key={item.id}
                        item={item}
                        supplierItems={supplierItems} />
                )} />
        </Layout>
    )
}
