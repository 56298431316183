import React from 'react'
import { NavLink } from 'react-router-dom'
import { useModal } from '../hooks/modal'
import { useDeleteRequest } from '../hooks/request'
import { SUPPLIER_DETAIL } from '../urls'
import Button from './common/Button'
import SupplierUpdate from './SupplierUpdate'

export default function SupplierItem({ item, userList, territory, index, suppliers }) {
    const [showUpdateModal, setShowUpdateModal] = useModal(<SupplierUpdate suppliers={suppliers} userList={userList} item={item} hideModal={() => setShowUpdateModal()} territory={territory} />)

    const productRemove = useDeleteRequest({ url: SUPPLIER_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            suppliers.request()
        }
    }

    return (
        <tr key={item.id}>
            <td className="is-size-6">{index + 1}</td>

            <td className="is-size-6">
                <NavLink to={`/supplier/${item.id}/checks`}>{item.name}</NavLink>
            </td>

            <td className="is-size-6">{item.phone}</td>
            <td className="is-size-6">{item.phoneDirector}</td>
            <td className="is-size-6">{item.phoneAccountant} </td>
            <td className="is-size-6">{Math.floor(Math.random() * (9999999 - 1000000) + 1000000)} </td>
            <td className="is-size-6">{item.phoneAccountant} </td>
            <td className="is-size-6">{item.phoneAccountant} </td>
            <td className="is-size-6">{Math.floor(Math.random() * (3 - 1) + 1) === 1 ? 'Да' : 'Нет'} </td>
            <td className="">
                <div className="is-pulled-right">
                    <Button onClick={showUpdateModal} className="mr-2 button is-success is-outlined is-small" icon="pencil-outline" />
                    <Button onClick={onDelete} className="button is-danger mr-2 is-outlined is-small" icon="trash" />
                </div>
            </td>
        </tr>
    )
}
