import React from 'react'
import { Form, Formik } from 'formik'
import Button from '../common/Button'
import { required } from '../../utils/validators'
import Input from '../common/Input'
import { useLoad } from '../../hooks/request'
import { CLIENT_LIST } from '../../urls'

export default function PaymentForm({ onCancel, onSubmit, initialValues, loading, setClientId }) {
    const clients = useLoad({ url: CLIENT_LIST, params: { type: 'active' } }, [])

    const results = clients.response ? clients.response.results : []

    return (
        <Formik onSubmit={onSubmit} initialValues={{ client: '', name: '', description: '', amount: '', termDate: '', ...initialValues }}>
            <Form>
                <select onChange={(e) => setClientId(e.target.value)} name="client" id="client" style={{ width: '100%', padding: '10px', marginBottom: '5px' }}>
                    <option value="">Выберите клиента</option>
                    {results.map((client) => (
                        <option key={client.id} value={client.id}>
                            {client.name}
                        </option>
                    ))}
                </select>

                <Input name="amount" type="number" label="Цена" validate={required} />
                <Input name="description" component="textarea" label="Описание" />
                <div className="is-pulled-right">
                    <Button onClick={onCancel} icon="close-outline" text="Закрыть" className="is-danger" />
                    &nbsp;&nbsp;
                    <Button loading={loading} text="Сохранить" icon="add-outline" type="submit" className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
