import React from 'react'
import { usePostRequest } from '../hooks/request'
import { PRODUCT_LIST } from '../urls'
import { useMessage } from '../hooks/message'
import DocumentsProductForm from './DocumentsProductForm'

export default function DocumentsProductCreate({ onCancel, refresh, arrayHelpers }) {
    const [showMessage] = useMessage()
    const post = usePostRequest({ url: PRODUCT_LIST })

    async function onSubmit(data) {
        const { success, error, response } = await post.request({ data })
        if (success) {
            refresh()
            onCancel()
            showMessage('Успешно добавлен', 'is-success')
            arrayHelpers.push({
                ...response,
                quantity: '',
            })
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Добавить товар</b></h1>

            <DocumentsProductForm
                onCancel={onCancel}
                onSubmit={onSubmit}
                loading={post.loading}
            />
        </div>
    )
}
