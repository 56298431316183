import { getEnv } from './utils/environment'

export const MARKET_LIST = '/main/market'
export const MARKET_DETAIL = '/main/market/{id}'
export const ORDER_LIST = '/main/order'
export const ORDER_DETAIL = '/main/order/{id}'

export const SIGNOUT = '/users/sign-out'

export const ORDER_PRODUCT_LIST = '/main/order_product'
export const ORDER_PRODUCT_DETAIL = '/main/order_product/{id}'
export const TERRITORY_LIST = '/main/territory'
export const TERRITORY_DETAIL = '/main/territory/{id}'

export const SIGN_IN = '/users/sign-in'
export const SIGN_UP = '/users/sign-up'
export const SIGN_OUT = '/users/sign-out'
export const USER_SETTINGS = '/users/user_settings'
export const CHANGE_PASSWORD = '/users/change_password'
export const CONFIRM = '/users/confirm'
export const RESET_PASSWORD = '/users/reset_password'
export const RESET_LINK = '/users/reset_link'
export const USER_LIST = '/users/user'

export const PRODUCT_LIST = '/product/products'
export const BORROW_LIST = '/main/borrow'
export const TOTAL_BORROW_LIST = '/main/total_borrow'
export const BORROW_DETAIL = '/main/borrow/{id}'
export const PRODUCT_DETAIL = '/product/product/{id}'
export const BRAND_LIST = 'product/brand'
export const BRAND_DETAIL = 'product/brand/{id}'
export const CATEGORY_LIST = '/product/categories'
export const CATEGORY_PARENT_LIST = '/product/parent-categories'
export const CATEGORY_DETAIL = '/product/category/{id}'
export const CLIENT_LIST = 'main/client'
export const CLIENT_DETAIL = 'main/client/{id}'
export const PURCHASE_HISTORY_LIST = 'product/purchase_history'
export const PURCHASE_HISTORY_DETAIL = 'product/purchase_history/{id}'
export const SEND_MESSAGE = 'main/send_sms_getaway/{clientId}'
export const SEND_MESSAGE_SELECT = 'main/send_sms_select'
export const ALL_SEND_MESSAGE = 'main/send_sms_getaway'
export const ORDER_ARCHIVE_LIST = 'main/order-archive'
export const CLIENT_EXPORT = 'main/client/export'
export const PRODUCT_EXPORT = 'product/product/export'
export const CURRENCY_LIST = 'product/currency'
export const CURRENCY_DETAIL = 'product/currency/{id}'
export const UPLOAD_FILE = 'product/file_upload'
export const PRODUCT_HISTORY_LIST = 'main/purchase_history'
export const PRODUCT_HISTORY_DETAIL = 'main/purchase_history/{id}'
export const SUPPLIER_LIST = 'main/supplier'
export const SUPPLIER_DETAIL = 'main/supplier/{id}'
export const DOCUMENTS_LIST = 'main/documents'
export const DOCUMENTS_DETAIL = 'main/documents/{id}'
export const DOCUMENTS_PRODUCTS_LIST = 'main/documents_product'
export const DOCUMENTS_PRODUCTS_DETAIL = 'main/documents_product/{id}'
export const SUPPLIER_ITEM_LIST = 'main/supplier-item'
export const SUPPLIER_ITEM_DETAIL = 'main/supplier-item/{id}'

export const EXPENSE_REPORT_LIST = 'main/expense-report'
export const EXPENSE_REPORT_DETAIL = 'main/expense-report/{id}'
export const NOMENCLATURE_LIST = '/product/nomenclature'
export const NOMENCLATURE_DETAIL = '/product/nomenclature/{id}'

const backendUrl = process.env.REACT_APP_BASE_URL || `${window.location.protocol}//${window.location.hostname}`
export const domain = backendUrl.endsWith('/') ? backendUrl.substr(0, backendUrl.length - 1) : backendUrl

// eslint-disable-next-line no-nested-ternary
const frontUrl = getEnv() === 'production'
    ? (
        ''
    ) : getEnv() === 'staging' ? (
        ''
    ) : 'http://localhost:3000'

export const frontDomain = frontUrl.endsWith('/') ? frontUrl.substr(0, frontUrl.length - 1) : frontUrl
