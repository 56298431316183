import React, { useState } from 'react'
import { usePostRequest } from '../../hooks/request'
import { BORROW_LIST } from '../../urls'
import PaymentForm from './paymentForm'

export default function PaymentCreate({ onCancel, borrows, borrow }) {
    const [clientId, setClientId] = useState('')
    const products = usePostRequest({ url: BORROW_LIST })

    async function onSubmit(data, action) {
        const { success } = await products.request({ data: { ...data, amount: borrow ? -data.amount : data.amount, client: clientId } })

        if (success) {
            action.resetForm()
            borrows.request()
            onCancel()
        }
    }

    return <PaymentForm loading={products.loading} setClientId={setClientId} onCancel={onCancel} onSubmit={onSubmit} />
}
