import React, { useCallback, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { debounce } from 'lodash'
import Button from './common/Button'
import Input from './common/Input'
import Card from './common/Card'
import { format } from '../utils/number'
import { useLoad } from '../hooks/request'
import { PRODUCT_DETAIL, PRODUCT_LIST, SUPPLIER_LIST } from '../urls'
import Select from './common/Select'
import { currency, getMeasurement, paid, paymentMethod } from '../utils/types'
import Table from './common/Table'
import { required } from '../utils/validators'
import { useModal } from '../hooks/modal'
import DeleteModal from './common/DeleteModal'
import DocumentsProductUpdate from './DocumentsProductUpdate'
import DocumentsProductCreate from './DocumentsProductCreate'

export default function DocumentsForm({ loading, onCancel, onSubmit, initialValues = {} }) {
    const [params, setParams] = useState({})
    const productLoad = useLoad({ url: PRODUCT_LIST, params }, [params])
    const productItem = productLoad.response ? productLoad.response.results : []
    const products = productItem.map((item) => ({
        ...item,
        quantity: '',
        count: item?.count || '',
        price: item?.price || '',
        priceSale: item?.priceSale || '',
        priceTransfer: item?.priceTransfer || '',
    }))
    const suppliers = useLoad({ url: SUPPLIER_LIST })

    const [showProductCreate, hideProductCreate] = useModal((arrayHelpers) => (
        <DocumentsProductCreate
            onCancel={() => {
                hideProductCreate()
            }}
            refresh={productLoad.request}
            arrayHelpers={arrayHelpers}
        />
    ))
    const [showProductEdit, hideProductEdit] = useModal((props) => (
        <DocumentsProductUpdate
            onCancel={() => {
                hideProductEdit()
            }}
            refresh={productLoad.request}
            item={props.item}
            arrayHelp={props.arrayHelp}
        />
    ))

    const [showDeleteProduct, hideDeleteProduct] = useModal((item) => (
        <DeleteModal
            onCancel={() => {
                hideDeleteProduct()
            }}
            refresh={productLoad.request}
            url={PRODUCT_DETAIL.replace('{id}', item?.id)}
            name={item?.name}
        />
    ))

    const debouncedSearch = debounce((search) => {
        setParams((prev) => ({ ...prev, search }))
    }, 500)
    const handleSearch = useCallback(
        ({ target: { value } }) => {
            debouncedSearch(value)
        },
        [debouncedSearch],
    )

    const handleSubmit = (vals, actions) => {
        const data = {
            ...vals,
            dateReturn: vals.dateReturn || undefined,
            invoice: vals.invoice ? +vals.invoice : undefined,
            wholesale: vals.wholesale ? +vals.wholesale : undefined,
            markup: vals.markup ? +vals.markup : undefined,
            vat: vals.vat ? +vals.vat : undefined,
            expenses: vals.expenses ? +vals.expenses : undefined,
        }
        onSubmit(data, actions)
    }

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={{
                products: [],
                supplier: '',
                dateReturn: '',
                currency: '',
                paid: '',
                paymentMethod: '',
                invoice: '',
                wholesale: '',
                markup: '',
                sales: '',
                margin: '',
                vat: '',
                note: '',
                expenses: '',
                ...initialValues,
            }}
        >
            {({ values, setFieldValue }) => {
                // Calculate totals
                const totals = values.products.reduce(
                    (acc, product) => {
                        const quantity = product.quantity ? +product.quantity : 0
                        const price = product.price ? +product.price : 0
                        const priceSale = product.priceSale ? +product.priceSale : 0
                        acc.totalQuantity += quantity
                        acc.totalPurchaseSum += quantity * price
                        acc.totalSaleSum += quantity * priceSale

                        return acc
                    },
                    { totalQuantity: 0, totalPurchaseSum: 0, totalSaleSum: 0 },
                )
                return (
                    <Form>
                        <Card>
                            <div className="columns">
                                <div className="column">
                                    <Input disabled placeholder="BekStroyMarket" name="name" label="Торговая точка*" />
                                </div>

                                <div className="column">
                                    <Select validate={required} empty options={suppliers.response ? suppliers.response.results : []} name="supplier" label="Поставщик*" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Input name="dateReturn" label="Дата возврата" type="number" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Select
                                        options={currency}
                                        optionValue="value"
                                        name="currency"
                                        label="Валюта*"
                                        onChange={(e) => {
                                            setFieldValue('currency', e.target.value)
                                            setFieldValue('products', [])
                                        }}
                                        disabled={loading}
                                    />
                                </div>

                                <div className="column">
                                    <Select options={paid} optionValue="value" name="paid" label="Оплачено*" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Select options={paymentMethod} optionValue="value" name="paymentMethod" label="Способ оплаты*" disabled={loading} />
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <Input step="0.01" type="number" name="invoice" label="№ Накладной" disabled={loading} />
                                </div>

                                <div className="column">
                                    <Input step="0.01" type="number" name="wholesale" label="Наценка оптовая" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Input step="0.01" type="number" name="markup" label="Наценка продажная" disabled={loading} />
                                </div>

                                <div className="column">
                                    <Input step="0.01" type="number" name="vat" label="НДС" disabled={loading} />
                                </div>

                                <div className="column">
                                    <Input name="note" label="Примечание" disabled={loading} />
                                </div>
                                <div className="column">
                                    <Input step="0.01" type="number" name="expenses" label="Расходы" disabled={loading} />
                                </div>
                            </div>
                        </Card>
                        <br />

                        <Card>
                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => (
                                    <>
                                        <Table
                                            showEmptyMessage={false}
                                            columns={{
                                                name: 'Наименование товара',
                                                code: 'Штрих-код',
                                                remains: 'Остаток',
                                                quantity: 'Количество',
                                                measurement: 'Ед. изм.',
                                                price: 'Цена поступление\t',
                                                price_transfer: 'Оптом цена\t',
                                                price_sale: 'Цена за единицу\t',
                                                summ: 'Сумма',
                                                actions: '',
                                            }}
                                            items={values.products}
                                            renderItem={(item, index) => {
                                                // const quantity = +values.products?.[index].quantity || 0
                                                // const price = +values.products[index].price

                                                const productCurrency = values.products?.[index]?.paymentType
                                                const count = values.products?.[index]?.count
                                                const quantity = values.products?.[index]?.quantity
                                                const bodyPrice = productCurrency === 'sum' ? values.products?.[index]?.sumPrice : values.products?.[index]?.usdPrice
                                                const Price = values.products?.[index]?.price
                                                const SalePrice = productCurrency === 'sum' ? values.products?.[index]?.sumPriceSale : values.products?.[index]?.usdPriceSale
                                                const Sale = values.products?.[index]?.priceSale

                                                const SaleOptomPrice = productCurrency === 'sum' ? values.products?.[index].sumPriceTransfer : values.products?.[index].sumPriceTransfer
                                                const SaleOptom = values.products?.[index].priceTransfer
                                                const sum = format(count * bodyPrice)

                                                return (
                                                    <tr key={item.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.codeContent}</td>
                                                        <td>{item.count}</td>
                                                        <td>
                                                            <Input
                                                                value={quantity || count}
                                                                validate={required}
                                                                type="number"
                                                                className="is-size-7"
                                                                name={`products[${index}].quantity`}
                                                                disabled={loading}
                                                            />
                                                        </td>
                                                        <td>{getMeasurement(item.measurement)}</td>
                                                        <td>
                                                            <Input
                                                                value={Price || bodyPrice}
                                                                validate={required}
                                                                type="number"
                                                                className="is-size-7"
                                                                name={`products[${index}].price`}
                                                                disabled={loading}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                value={SaleOptom || SaleOptomPrice}
                                                                validate={required}
                                                                type="number"
                                                                className="is-size-7"
                                                                name={`products.${index}.priceTransfer`}
                                                                disabled={loading}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                value={Sale || SalePrice}
                                                                validate={required}
                                                                type="number"
                                                                className="is-size-7"
                                                                name={`products.${index}.priceSale`}
                                                                disabled={loading}
                                                            />
                                                        </td>
                                                        <td>{sum ?? ''}</td>
                                                        <td>
                                                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                                                <Button
                                                                    onClick={() => {
                                                                        showProductEdit({ item, arrayHelp: { index, arrayHelpers } })
                                                                    }}
                                                                    icon="create-outline"
                                                                    className="is-size-7"
                                                                    disabled={loading}
                                                                />
                                                                <Button
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                    icon="close-outline"
                                                                    className="is-danger is-outlined is-size-7"
                                                                    disabled={loading}
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }}
                                        />

                                        <div className="is-flex mt-6">
                                            <input type="search" className="input" onChange={handleSearch} />

                                            <div className="ml-2">
                                                <Button
                                                    onClick={() => {
                                                        showProductCreate(arrayHelpers)
                                                    }}
                                                    icon="add-outline"
                                                    className="is-outlined"
                                                    disabled={loading}
                                                />
                                                &nbsp;&nbsp;
                                            </div>
                                        </div>

                                        {params.search && (
                                            <Table
                                                columns={{
                                                    name: 'Наименование товара',
                                                    code: 'Штрих-код',
                                                    quantity: 'Количество',
                                                    actions: '',
                                                }}
                                                items={products.filter((prod) => !values.products.map((p) => p?.id).includes(prod?.id))}
                                                renderItem={(item) => (
                                                    <tr key={item?.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.codeContent}</td>
                                                        <td>
                                                            {item?.count} {getMeasurement(item?.measurement)}
                                                        </td>
                                                        <td style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                            <Button
                                                                onClick={() => {
                                                                    showProductEdit({ item })
                                                                }}
                                                                icon="create-outline"
                                                                className="is-size-7"
                                                                disabled={loading}
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    showDeleteProduct(item)
                                                                }}
                                                                icon="trash-outline"
                                                                className="is-size-7 is-danger is-outlined"
                                                                disabled={loading}
                                                            />
                                                            {item?.paymentType === values.currency ? (
                                                                <Button
                                                                    onClick={() => {
                                                                        arrayHelpers.push(item)
                                                                    }}
                                                                    icon="add-outline"
                                                                    className="is-size-7"
                                                                    disabled={loading}
                                                                />
                                                            ) : (
                                                                <span className="has-text-danger">Выберите другую валюту для добавления</span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            />

                            <div className="my-4">
                                <div className="is-pulled-left">
                                    <span className="tag is-large is-link is-light mr-2">Общее количество: {totals.totalQuantity.toFixed(2)}</span>
                                    <span className="tag is-large is-link is-light mr-2">Сумма прихода: {totals.totalPurchaseSum.toFixed(2)}</span>
                                    <span className="tag is-large is-link is-light">Сумма продаж: {totals.totalSaleSum.toFixed(2)}</span>
                                </div>
                                <div className="is-pulled-right">
                                    <Button onClick={onCancel} icon="close-outline" text="Отмена" className="is-danger" disabled={loading} />
                                    &nbsp;&nbsp;
                                    <Button text="Сохранить" icon="add-outline" type="submit" className="is-success" disabled={loading} />
                                </div>
                            </div>

                            <br />
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}
